import styles from '../styles/nav.module.scss';
import React, {useState, useEffect, useContext} from 'react';
import cx from "classnames";
import { Link } from "react-router-dom";

import { UserContext } from '../Context/UserContext';

import {
  FiChevronRight,
  FiChevronLeft,
  FiHome,
  FiList,
  FiTruck,
  FiPlus,
  FiPlayCircle,
  FiImage,
  FiUsers,
  FiLogOut, FiFileText, FiAlignLeft
} from "react-icons/fi";

export default function SideBar() {

  const {setCurrUser} = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false);

  const openSideBar = (value)=>{
    if(value){
      document.getElementById("sideBar").classList.add(styles.openSideBar);
    }else{
      document.getElementById("sideBar").classList.remove(styles.openSideBar);
    }
    setIsOpen(value);
  }

  const logOut = ()=>{
    sessionStorage.removeItem("currUser");
    setCurrUser({});
  }

  return (
    <div className={styles.sideBar} id='sideBar' >
      {/* <span className={styles.sideBarTitle} >
      CMS <span className={styles.sideBarTitle} style={{color:'#FF731D'}} >admin</span>
      </span> */}
      <div className={styles.taqniyatImgWrapper} >
        <div className={styles.taqniyatImg} >
          <img alt="taqniyat" src='https://taqniyat.sa/assets/images/logo.png' />
        </div>
      </div>
      <button className={styles.openSideBarBtn} onClick={()=> openSideBar(!isOpen) } >
        {
          isOpen
          ? <FiChevronLeft size={32} />
          : <FiChevronRight size={32} />
        }
      </button>

      <div className={styles.sideBarContent} >
        <ul className={styles.sideBarElements}>
          <li>
            <Link
              onClick={() => openSideBar(false)}
              className={cx(`${styles.sideBarElement} ${styles.link}`, {
                [styles.closedSideBarElement]: !isOpen,
                [styles.openedSideBarElement]: isOpen
              })}
              to='/'
            >
              <FiPlayCircle size={24}/>
              {isOpen && <span className={styles.sideBarElementText}>All videos</span>}
            </Link>
          </li>
          {/* <li >
            <Link
              onClick={()=> openSideBar(false) }
              className={cx(`${styles.sideBarElement} ${styles.link}`,{[styles.closedSideBarElement]: !isOpen, [styles.openedSideBarElement]: isOpen})}
              to='/add'
            >
              <FiPlus size={22} />
              {isOpen && <span className={styles.sideBarElementText} >Add video</span>}
            </Link>
          </li> */}
          <li>
            <Link
              onClick={() => openSideBar(false)}
              className={cx(`${styles.sideBarElement} ${styles.link}`, {
                [styles.closedSideBarElement]: !isOpen,
                [styles.openedSideBarElement]: isOpen
              })}
              to='/images'
            >
              <FiImage size={22}/>
              {isOpen && <span className={styles.sideBarElementText}>Images</span>}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => openSideBar(false)}
              className={cx(`${styles.sideBarElement} ${styles.link}`, {
                [styles.closedSideBarElement]: !isOpen,
                [styles.openedSideBarElement]: isOpen
              })}
              to='/websites'
            >
              <FiList size={22}/>
              {isOpen && <span className={styles.sideBarElementText}>Web Sites</span>}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => openSideBar(false)}
              className={cx(`${styles.sideBarElement} ${styles.link}`, {
                [styles.closedSideBarElement]: !isOpen,
                [styles.openedSideBarElement]: isOpen
              })}
              to='/add-category'
            >
              <FiPlus size={22}/>
              {isOpen && <span className={styles.sideBarElementText}>Add Category</span>}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => openSideBar(false)}
              className={cx(`${styles.sideBarElement} ${styles.link}`, {
                [styles.closedSideBarElement]: !isOpen,
                [styles.openedSideBarElement]: isOpen
              })}
              to='/users'
            >
              <FiUsers size={22}/>
              {isOpen && <span className={styles.sideBarElementText}>Users</span>}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => openSideBar(false)}
              className={cx(`${styles.sideBarElement} ${styles.link}`, {
                [styles.closedSideBarElement]: !isOpen,
                [styles.openedSideBarElement]: isOpen
              })}
              to='/blogs'
            >
              <FiFileText size={22}/>
              {isOpen && <span className={styles.sideBarElementText}>Blogs</span>}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => openSideBar(false)}
              className={cx(`${styles.sideBarElement} ${styles.link}`, {
                [styles.closedSideBarElement]: !isOpen,
                [styles.openedSideBarElement]: isOpen
              })}
              to='/texts'
            >
              <FiAlignLeft size={22}/>
              {isOpen && <span className={styles.sideBarElementText}>Texts</span>}
            </Link>
          </li>
        </ul>
        <ul className={styles.sideBarElements}>
          <li>
            <Link
              onClick={() => logOut()}
              to='/'
              className={cx(`${styles.sideBarElement} ${styles.link}`, {
                [styles.closedSideBarElement]: !isOpen,
                [styles.openedSideBarElement]: isOpen
              })}
            >
              <FiLogOut size={24}/>
              {isOpen && <span className={styles.sideBarElementText}>Log Out</span>}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
