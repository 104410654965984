import React, {useState, useEffect, useContext} from 'react';
import styles from '../styles/images.module.scss';
import { FiEdit, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import axios from 'axios';
import cx from 'classnames';

import { UserContext } from '../Context/UserContext';
import Modal from './Modal';

function AddModal({close, apiUrl}){

  const [enTitle, setEnTitle] = useState("");
  const [arTitle, setArTitle] = useState("");
  const [isSiteSelectOpen, setIsSiteSelectOpen] = useState(false);
  const [isCategoriesSelectOpen, setIsCategoriesSelectOpen] = useState(false);
  const [site, setSite] = useState({});
  const [category, setCategory] = useState({});
  const [img, setImg] = useState(null);
  const [imgUrl, setImgUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);

  const getAllSites = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sites`);
      setSites(response.data);
    } catch (error) {
      console.error('Error fetching sites:', error);
      throw new Error('Failed to fetch sites');
    }
  };
  const getAllCategoriesForSite = async (siteId) => {
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);
      console.log(response.data);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  };

  const selectSite = (site)=>{
    setSite(site);
    setCategory({});
    setIsSiteSelectOpen(false);
    setIsCategoriesSelectOpen(false);
  }
  const selectCat = (cat)=>{
    setCategory(cat);
    setIsCategoriesSelectOpen(false);
    setIsSiteSelectOpen(false);
  }

  const addImage = async ()=>{
    if(Object.keys(site).length === 0){
      setErrorMsg("Please select a specific Site");
      return;
    }
    if(Object.keys(category).length === 0){
      setErrorMsg("Please select a category");
      return;
    }
    if(imgUrl.length === 0){
      setErrorMsg("Please add a image first");
      return;
    }
    if(enTitle.length === 0){
      setErrorMsg("Please add an english title");
      return;
    }
    if(arTitle.length === 0){
      setErrorMsg("Please add an arabic title");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/images/add`, {
        enTitle,
        arTitle,
        url: imgUrl,
        siteId: site.id,
        categoryId: category.id
      });

      console.log(response.data);
      close();
    } catch (error) {
      console.error('Error adding image', error);
      // Handle the error appropriately
      throw error;
    }

  }

  useEffect(()=>{
    if( Object.keys(site).length > 0 ){
      getAllCategoriesForSite(site.id);
    }
  },[site]);

  useEffect(()=>{
    getAllSites();
  },[]);

  return(
    <div>
      {errorMsg && <p style={{color:"#D6323A", textAlign: 'center'}} >{errorMsg}</p> }
      <div style={{display: 'flex',justifyContent: 'space-between', gap: '12px'}} >
        <div className={styles.selectWrapper} >
          <label>Web site</label>
          <button className={styles.selectBtn} onClick={()=> setIsSiteSelectOpen(!isSiteSelectOpen) } >
            {
              Object.keys(site).length > 0
              ? <span style={{color: '#000'}} >{site.name}</span>
              : <span style={{color: '#888'}} >Web site</span>
            }
            {
              isSiteSelectOpen
              ? <FiChevronUp size={22} />
              : <FiChevronDown size={22} />
            }
          </button>
          {
            isSiteSelectOpen &&
            <div className={styles.selectMenu} >
              <ul>
                {
                  sites.map((it)=>
                    <li key={it.id} onClick={()=> selectSite(it) } className={cx({[styles.activeItem]: site.id === it.id})} >{it.name}</li>
                  )
                }
              </ul>
            </div>
          }
        </div>
        <div className={styles.selectWrapper} >
          <label>Category</label>
          <button className={styles.selectBtn} onClick={()=> setIsCategoriesSelectOpen(!isCategoriesSelectOpen) } >
            {
              Object.keys(category).length > 0
              ? <span style={{color: '#000'}} >{category.en_title}</span>
              : <span style={{color: '#888'}} >Category</span>
            }
            {
              isCategoriesSelectOpen
              ? <FiChevronUp size={22} />
              : <FiChevronDown size={22} />
            }
          </button>
          {
            isCategoriesSelectOpen &&
            <div className={styles.selectMenu} >
              <ul>
                {
                  categories.length > 0 &&
                  categories.map((it)=> it.cat_type === "image" &&
                    <li key={it.id} onClick={()=> selectCat(it) } className={cx({[styles.activeItem]: category.id === it.id})} >{it.en_title}</li>
                  )
                }
              </ul>
            </div>
          }
        </div>
      </div>
      <div className={styles.inputWrapper} >
        <label>English title</label>
        <input
          placeholder='English title'
          value={enTitle}
          onChange={(e)=> setEnTitle(e.target.value) }
        />
      </div>
      <div className={styles.inputWrapper} >
        <label>Arabic title</label>
        <input
          placeholder='Arabic title'
          value={arTitle}
          onChange={(e)=> setArTitle(e.target.value) }
        />
      </div>
      <div className={styles.inputWrapper} >
        <label>Image url</label>
        <input
          placeholder='Image url'
          value={imgUrl}
          onChange={(e)=> setImgUrl(e.target.value) }
        />
      </div>
      <button className={styles.submitBtn} onClick={()=> addImage() } >
        Add image
      </button>
    </div>
  )
}

function UpdateModal({item, close, apiUrl}){
  const [enTitle, setEnTitle] = useState("");
  const [arTitle, setArTitle] = useState("");
  const [isSiteSelectOpen, setIsSiteSelectOpen] = useState(false);
  const [isCategoriesSelectOpen, setIsCategoriesSelectOpen] = useState(false);
  const [site, setSite] = useState({});
  const [category, setCategory] = useState({});
  const [img, setImg] = useState(null);
  const [imgUrl, setImgUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);

  const getAllSites = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sites`);
      setSites(response.data);
    } catch (error) {
      console.error('Error fetching sites:', error);
      throw new Error('Failed to fetch sites');
    }
  };
  const getAllCategoriesForSite = async (siteId) => {
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  };

  const selectSite = (site)=>{
    setSite(site);
    setCategory({});
    setIsSiteSelectOpen(false);
    setIsCategoriesSelectOpen(false);
  }
  const selectCat = (cat)=>{
    setCategory(cat);
    setIsCategoriesSelectOpen(false);
    setIsSiteSelectOpen(false);
  }

  const updateImage = async ()=>{
    if(Object.keys(site).length === 0){
      setErrorMsg("Please select a specific Site");
      return;
    }
    if(Object.keys(category).length === 0){
      setErrorMsg("Please select a category");
      return;
    }
    if(imgUrl.length === 0){
      setErrorMsg("Please add a image first");
      return;
    }
    if(enTitle.length === 0){
      setErrorMsg("Please add an english title");
      return;
    }
    if(arTitle.length === 0){
      setErrorMsg("Please add an arabic title");
      return;
    }

    try {
      const response = await axios.put(`${apiUrl}/images/update`, {
        id: item.id,
        enTitle,
        arTitle,
        url: imgUrl,
        siteId: site.id,
        categoryId: category.id
      });

      console.log(response.data);
      close(true);
    } catch (error) {
      console.error('Error updating image', error);
      // Handle the error appropriately
      throw error;
    }

  }

  const getSiteById = (id)=>{
    axios.get(`${apiUrl}/site/${id}`)
    .then(response => {
      setSite(response.data);
    })
    .catch(error => {
      console.error(error);
    });
  }
  const getCatById = (id)=>{
    axios.get(`${apiUrl}/category/${id}`)
    .then(response => {
      setCategory(response.data);
    })
    .catch(error => {
      console.error(error);
    });
  }

  useEffect(()=>{
    if( Object.keys(site).length > 0 ){
      getAllCategoriesForSite(site.id);
    }
  },[site])
  useEffect(()=>{
    getAllSites();
  },[]);

  useEffect(()=>{
    if( Object.keys(item).length > 0 ){
      setArTitle(item.ar_title);
      setEnTitle(item.en_title);
      setImgUrl(item.url);
      getCatById(item.category_id);
      getSiteById(item.site_id);
    }
  },[item])

  return(
    <div>
      {errorMsg && <p style={{color:"#D6323A", textAlign: 'center'}} >{errorMsg}</p> }
      <div style={{display: 'flex',justifyContent: 'space-between', gap: '12px'}} >
        <div className={styles.selectWrapper} >
          <label>Web site</label>
          <button className={styles.selectBtn} onClick={()=> setIsSiteSelectOpen(!isSiteSelectOpen) } >
            {
              Object.keys(site).length > 0
              ? <span style={{color: '#000'}} >{site.name}</span>
              : <span style={{color: '#888'}} >Web site</span>
            }
            {
              isSiteSelectOpen
              ? <FiChevronUp size={22} />
              : <FiChevronDown size={22} />
            }
          </button>
          {
            isSiteSelectOpen &&
            <div className={styles.selectMenu} >
              <ul>
                {
                  sites.map((it)=>
                    <li key={it.id} onClick={()=> selectSite(it) } className={cx({[styles.activeItem]: site.id === it.id})} >{it.name}</li>
                  )
                }
              </ul>
            </div>
          }
        </div>
        <div className={styles.selectWrapper} >
          <label>Category</label>
          <button className={styles.selectBtn} onClick={()=> setIsCategoriesSelectOpen(!isCategoriesSelectOpen) } >
            {
              Object.keys(category).length > 0
              ? <span style={{color: '#000'}} >{category.en_title}</span>
              : <span style={{color: '#888'}} >Category</span>
            }
            {
              isCategoriesSelectOpen
              ? <FiChevronUp size={22} />
              : <FiChevronDown size={22} />
            }
          </button>
          {
            isCategoriesSelectOpen &&
            <div className={styles.selectMenu} >
              <ul>
                {
                  categories.length > 0 &&
                  categories.map((it)=> it.type === "image" &&
                    <li key={it.id} onClick={()=> selectCat(it) } className={cx({[styles.activeItem]: category.id === it.id})} >{it.en_title}</li>
                  )
                }
              </ul>
            </div>
          }
        </div>
      </div>
      <div className={styles.inputWrapper} >
        <label>English title</label>
        <input
          placeholder='English title'
          value={enTitle}
          onChange={(e)=> setEnTitle(e.target.value) }
        />
      </div>
      <div className={styles.inputWrapper} >
        <label>Arabic title</label>
        <input
          placeholder='Arabic title'
          value={arTitle}
          onChange={(e)=> setArTitle(e.target.value) }
        />
      </div>
      <div className={styles.inputWrapper} >
        <label>Image url</label>
        <input
          placeholder='Image url'
          value={imgUrl}
          onChange={(e)=> setImgUrl(e.target.value) }
        />
      </div>
      <button className={styles.submitBtn} onClick={()=> updateImage() } >
        Update the image
      </button>
    </div>
  )
}

function ImgWrapper ({item, update, apiUrl}){

  const [site, setSite] = useState({});
  const [cat, setCat] = useState({});

  const getSiteById = (id)=>{
    axios.get(`${apiUrl}/site/${id}`)
    .then(response => {
      setSite(response.data);
    })
    .catch(error => {
      console.error(error);
    });
  }
  const getCatById = (id)=>{
    axios.get(`${apiUrl}/category/${id}`)
    .then(response => {
      setCat(response.data);
    })
    .catch(error => {
      console.error(error);
    });
  }

  useEffect(()=>{
    if(Object.keys(item).length > 0){
      getSiteById(item.site_id);
      getCatById(item.category_id);
    }
  },[item])

  return (
    <div className={styles.imgWrapper} >
      <img src={item.url} />
      <span>{item.en_title}</span>
      <div className={styles.darkOverlay} >
        <div>
          <ul>
            <li>{site.name}</li>
            <li>{cat.en_title}</li>
          </ul>
        </div>
        <div>
          <FiEdit size={22} color='#fff' style={{cursor: 'pointer'}} onClick={()=> update(item) } />
        </div>
      </div>
    </div>
  )
}

export default function Images() {

  const {apiUrl} = useContext(UserContext);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateModalData, setUpdateModalData] = useState({});
  const [images, setImages] = useState([]);

  const openAddModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsAddModalOpen(true);
  }
  const closeAddModal = (isUpdated)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsAddModalOpen(false);
    if(isUpdated){
      getAllImages();
    }
  }
  const openUpdateModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsUpdateModalOpen(true);
    setUpdateModalData(data)
  }
  const closeUpdateModal = (isUpdated)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsUpdateModalOpen(false);
    setUpdateModalData({});
    if(isUpdated){
      getAllImages();
    }
  }

  const getAllImages = async () => {
    try {
      const response = await axios.get(`${apiUrl}/images`);
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images', error);
      // Handle the error appropriately
      // throw error;
    }
  };

  useEffect(()=>{
    getAllImages();
  },[])

  return (
    <div className={styles.container} >
      <div className={styles.header} >
        <h2>List of Images</h2>
        <button className={styles.addBtn} onClick={()=> openAddModal() } >
          Add image
        </button>
      </div>
      <div className={styles.imagesWrapper} >
        {
          images.length > 0 && images.map((it)=> <ImgWrapper key={it.id} item={it} update={openUpdateModal} apiUrl={apiUrl} /> )
        }
      </div>

      <Modal isOpen={isAddModalOpen} close={closeAddModal} title="Add new image" >
        <AddModal close={closeAddModal} apiUrl={apiUrl} />
      </Modal>
      <Modal isOpen={isUpdateModalOpen} close={closeUpdateModal} title="Update an image" >
        <UpdateModal item={updateModalData} close={closeUpdateModal} apiUrl={apiUrl} />
      </Modal>
    </div>
  )
}
