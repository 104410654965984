import React, {useState, useEffect, useContext} from 'react';
import styles from '../styles/home.module.scss';
import { FiChevronDown, FiChevronUp, FiPlay } from 'react-icons/fi';
import cx from "classnames";
import { Link } from "react-router-dom"
import axios from 'axios';

import { UserContext } from '../Context/UserContext';

import vid_1 from '../files/vid_1.png';
import Modal from './Modal';
import VimeoPlayer from './VimeoPlayer';

const hiyaCategories = ["Your beauty", "Your career", "Your personality", "Nine months' journey"];
const alphaWorldCategories = ["Turkish World", "Chineses World", "English World", "French World"];

function VideoContainer({item, openVideoModal, portalsApi}){
  const [thumbnail, setThumbnail] = useState('');
  const videoId = item.video_url.split('/').pop();

  const confirmDeleteAlert = async () => {
    const result = window.confirm('Do you want to deletet video name = '+item.en_title+" ?");
    const accessToken = '16844001bc2db2ba07f94bb17c8934b7';
    const apiUrl = `https://api.vimeo.com/videos/${videoId}`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    if (result) {
      // axios.delete(apiUrl, { headers })
      // .then(async response => {
      //   if (response.status === 204) {
      //     const response = await axios.delete(`${portalsApi}/delete/${item.id}`);
      //     console.log(response.data);
      //     console.log(`Video ${videoId} deleted successfully.`);
      //   } else {
      //     const response = await axios.delete(`${portalsApi}/delete/${item.id}`);
      //     console.error(`Error deleting video. Status code: ${response.status}, Response: ${response.data}`);
      //   }
      // })
      // .catch(error => {
      //   console.error('Error:', error.message);
      // });
      const response = await axios.delete(`${portalsApi}/delete/${item.id}`);
      console.log(response.data);
      console.log(`Video ${videoId} deleted successfully.`);
    } else {
      console.log('User clicked Cancel');
    }
  };


  const fetchThumbnail = async () => {
    try {
      const response = await axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`);
      // console.log(response.data[0]);
      setThumbnail(response.data[0].thumbnail_large+".jpg");
    } catch (error) {
      console.error('Error fetching Vimeo thumbnail:', error);
    }
  };

  useEffect(() => {
    fetchThumbnail();
  }, []);

  return(
    <div key={item.id} className={styles.videoWrapper} >
      <div className={styles.thumbnail} onClick={()=> openVideoModal({en_title: item.en_title, id: videoId}) } >
        <img src={thumbnail} style={{width: '100%', height: '100%', objectFit: "cover", borderRadius: 12}} />
        <div className={styles.videoDarkOverlay} >
          <FiPlay color="#fff" size={36} />
        </div>
      </div>
      <p>{item.en_title}</p>
      <p style={{textAlign: 'right'}} >{item.ar_title}</p>
      <div className={styles.videoBtnsWrapper} >
        <Link to={`/edit/${item.id}`} style={{textDecoration: 'none' }} >
          <span style={{color: "#1746A2", textDecoration: 'none' }} >Edit</span>
        </Link>
        <span style={{color: "#D6323A"}} onClick={()=> confirmDeleteAlert() } >Delete</span>
      </div>
    </div>
  )
}

export default function Home() {

  const {apiUrl} = useContext(UserContext);

  const [lastFilter, setLastFilter] = useState("");
  const [isSiteFilterOpen, setIsSiteFilterOpen] = useState(false);
  const [isCatFilterOpen, setIsCatFilterOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState({});
  const [selectedCat, setSelectedCat] = useState({});
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);

  const selectSite = (site)=>{
    if(site.id === selectedSite.id){
      setSelectedSite({});
    }else{
      setSelectedSite(site);
    }
    setSelectedCat({});
    setCategories([]);
    toggleFilter("site");
  }
  const selectCat = (cat)=>{
    if(cat.id === selectedCat.id){
      setSelectedCat({});
    }else{
      setSelectedCat(cat);
    }
    toggleFilter("cat");
  }
  const openVideoModal = (item)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsVideoModalOpen(true);
    setModalData(item);
  }
  const closeVideoModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsVideoModalOpen(false);
    setModalData({});
  }

  const getAllSites = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sites`);
      setSites(response.data);
    } catch (error) {
      console.error('Error fetching sites:', error);
      throw new Error('Failed to fetch sites');
    }
  };
  const getAllCategoriesForSite = async (siteId) => {
    console.log(siteId);
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);
      console.log(response.data);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  };

  const getVideos = async () => {
    try {
      const response = await axios.get(apiUrl);
      setVideos(response.data);
    } catch (error) {
      console.log('There was an error fetching the videos: ' + (error.response ? error.response.data : error.message));
    } finally {
      setLoading(false);
    }
  };
  const getFiltredVideos = async () => {
    let url = `${apiUrl}/filter`;

    if (Object.keys(selectedSite).length > 0) {
      url += `/${selectedSite.id}`;
      console.log(url);
    }
    if (Object.keys(selectedCat).length > 0 ) {
      url += `/${selectedCat.id}`;
    }

    try {
      axios.get(url)
      .then((response) => {
        setVideos(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    } catch (error) {
      console.error(error);
    }
  };

  const closeLastFilter = ()=>{
    switch (lastFilter) {
      case "site":
        setIsSiteFilterOpen(false);
        break;
      case "cat":
        setIsCatFilterOpen(false)
        break;
      default:
        break;
    }
  }
  const toggleFilter = (type)=>{
    closeLastFilter();
    switch (type) {
      case "site":
        setIsSiteFilterOpen(!isSiteFilterOpen);
        setLastFilter("site");
        break;
      case "cat":
        setIsCatFilterOpen(!isCatFilterOpen);
        setLastFilter("cat");
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if(apiUrl){
      if(Object.keys(selectedCat).length === 0 && Object.keys(selectedSite).length === 0){
        getVideos();
      }else{
        getFiltredVideos();
      }
    }
  }, [selectedCat, selectedSite, apiUrl]);

  useEffect(()=>{
    if( Object.keys(selectedSite).length > 0 ){
      getAllCategoriesForSite(selectedSite.id);
    }
  },[selectedSite]);

  useEffect(()=>{
    if(apiUrl){
      getAllSites();
    }
  },[apiUrl]);

  return (
    <div>
      <div className={styles.header} >
        <h2>List of Videos</h2>
        <div className={styles.filtersContainer} >
          <div className={styles.filterWrapper} >
            <button className={styles.filterBtn} onClick={()=> toggleFilter("site") } >
              {
                Object.keys(selectedSite).length > 0
                ? <span style={{color: '#000'}} >{selectedSite.name}</span>
                : <span style={{color: '#888'}} >Web site</span>
              }
              {
                isSiteFilterOpen 
                ? <FiChevronUp size={22} />
                : <FiChevronDown size={22} />
              }
            </button>
            {
              isSiteFilterOpen &&
              <div className={styles.filterMenu} >
                <ul>
                  {
                    sites.map((it)=>
                      <li key={it.id} onClick={()=> selectSite(it) } className={cx({[styles.activeItem]:selectedSite.id === it.id})} >{it.name}</li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
          <div className={styles.filterWrapper} >
            <button className={styles.filterBtn} onClick={()=> toggleFilter("cat") } >
              {
                Object.keys(selectedCat).length > 0
                ? <span style={{color: '#000'}} >{selectedCat.en_title}</span>
                : <span style={{color: '#888'}} >Category</span>
              }
              {
                isCatFilterOpen 
                ? <FiChevronUp size={22} />
                : <FiChevronDown size={22} />
              }
            </button>
            {
              isCatFilterOpen &&
              <div className={styles.filterMenu} >
                <ul>
                  { categories.length > 0 &&
                    categories.map((it)=> it.cat_type === "video" && <li key={it.id} onClick={()=> selectCat(it) } className={cx({[styles.activeItem]:selectedCat.id === it.id})} >{it.en_title}</li> )
                  }
                </ul>
              </div>
            }
          </div>
          <Link to="/add" >
            <button className={styles.addBtn}>
              Add video
            </button>
          </Link>
        </div>
      </div>
      <div className={styles.videosContainer} >
        { videos.length > 0 && videos.map((it)=> <VideoContainer key={it.id} item={it} openVideoModal={openVideoModal} portalsApi={apiUrl} /> )}
      </div>

      {/* <Vimeo
        video="https://vimeo.com/882258160"
        autoplay
      /> */}

      <Modal isOpen={isVideoModalOpen} close={closeVideoModal} title={modalData.en_title} >
        <iframe src={`https://player.vimeo.com/video/${modalData.id}?byline=0&portrait=0&title=0`} width={window.innerWidth*0.6} height={window.innerHeight*0.6} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </Modal>
    </div>
  )
}
