import React,{ createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const [apiUrl, setApiUrl] = useState('');
  const [currUser, setCurrUser] = useState({});

  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(config =>{
        setApiUrl(config.apiUrl);
      });
  }, []);

  useEffect(()=>{
    let retrievedObjectString = sessionStorage.getItem("currUser");
    if (retrievedObjectString){
      let retrievedObject = JSON.parse(retrievedObjectString);
      setCurrUser(retrievedObject);
      console.log(retrievedObject);
    }
  },[]);

  const values = {
    apiUrl,
    currUser,
    setCurrUser
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
};