import React, {useContext, useEffect, useState} from 'react';
import styles from "../styles/newBlog.module.scss";
import Modal from "./Modal";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import {UserContext} from "../Context/UserContext";
import {useHistory, useParams} from "react-router-dom";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import cx from "classnames";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['clean'],
    [{ 'direction': 'rtl' }]
  ],
};

function EditBlog(props) {

  const {apiUrl} = useContext(UserContext);
  const { id } = useParams();
  const history = useHistory();

  const [value, setValue] = useState('');
  const [valueAr, setValueAr] = useState('');
  const [img, setImg] = useState(null);
  const [imgSrc, setImgSrc] = useState('');
  const [title, setTitle] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [imgUrl, setImgUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [tagdesc, setTagdesc] = useState("");
  const [tagdescAr, setTagdescAr] = useState("");
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);

  const selectTag = (val)=>{
    setTagdesc(val);
    setIsTagMenuOpen(false);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImg(file);
    if (file && file.type.substr(0, 5) === 'image') {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgSrc(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImgSrc('');
    }
  };

  const editBlog = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${apiUrl}/blogs/update/${id}`, {
        img: imgUrl,
        body: value,
        title,
        bodyAr: valueAr,
        titleAr,
        tagdesc,
        tagdescAr
      }, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      });
      history.goBack();
      return response.data;
    } catch (error) {
      console.error('Error adding site:', error);
      throw new Error('Failed to add the site');
    } finally {
      setLoading(false);
    }
  };

  const getBlogById = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/blogs/show/${id}`);
      const data = response.data;
      setImgUrl(data.img);
      data.body && setValue(data.body);
      data.bodyAr && setValueAr(data.bodyAr);
      data.titleAr && setTitleAr(data.titleAr)
      data.title && setTitle(data.title);
      data.tagdesc && setTagdesc(data.tagdesc);
      data.tagdescAr && setTagdescAr(data.tagdescAr);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  }

  useEffect(() => {
    if(apiUrl && id){
      getBlogById(id)
    }
  }, [id, apiUrl]);

  return (
    <div className={styles.container}>
      <div className={styles.portalHeader}>
        <h2>Edit Blog</h2>
        <button className={styles.addPortal} onClick={() => editBlog()} disabled={loading}>
          {
            loading
              ? <div className="loader"/>
              : "Save blog"
          }
        </button>
      </div>
      <div className={styles.block}>
        <div className={styles.inputLabel}>
          <label>Blog image url</label>
        </div>
        <input
          type="text"
          placeholder="Image Url"
          value={imgUrl}
          className={styles.imgTextInput}
          onChange={(e) => setImgUrl(e.target.value)}
        />
        {/*<div >*/}
        {/*  <input*/}
        {/*    type="file"*/}
        {/*    placeholder='Document'*/}
        {/*    onChange={(e) => handleImageChange(e)}*/}
        {/*    className={styles.file_input}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      {img !== null &&
        <div className={styles.imgPrev}>
          <img src={imgSrc} alt="Selected"/>
        </div>
      }
      {/*<div className={styles.selectWrapper}>*/}
      {/*  <label>Category</label>*/}
      {/*  <button className={styles.selectBtn} onClick={() => setIsTagMenuOpen(!isTagMenuOpen)}>*/}
      {/*    {*/}
      {/*      tagdesc.length > 0*/}
      {/*        ? <span style={{color: '#000'}}>{tagdesc}</span>*/}
      {/*        : <span style={{color: '#888'}}>Category</span>*/}
      {/*    }*/}
      {/*    {*/}
      {/*      isTagMenuOpen*/}
      {/*        ? <FiChevronUp size={22}/>*/}
      {/*        : <FiChevronDown size={22}/>*/}
      {/*    }*/}
      {/*  </button>*/}
      {/*  {*/}
      {/*    isTagMenuOpen &&*/}
      {/*    <div className={styles.selectMenu}>*/}
      {/*      <ul>*/}
      {/*        {*/}
      {/*          tags.map((it) =>*/}
      {/*            <li key={it} onClick={() => selectTag(it)} className={cx({[styles.activeItem]: tagdesc === it})}>*/}
      {/*              {it}*/}
      {/*            </li>*/}
      {/*          )*/}
      {/*        }*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*</div>*/}
      <div style={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
        <div className={styles.block} style={{width: '50%'}}>
          <div className={styles.inputLabel}>
            <label>English tags</label>
          </div>
          <input
            type="text"
            placeholder="English tags"
            value={tagdesc}
            className={styles.imgTextInput}
            onChange={(e) => setTagdesc(e.target.value)}
          />
        </div>
        <div className={styles.block} style={{width: '50%'}}>
          <div className={styles.inputLabel}>
            <label>Arabic tags</label>
          </div>
          <input
            type="text"
            placeholder="Arabic tags"
            value={tagdescAr}
            className={styles.imgTextInput}
            onChange={(e) => setTagdescAr(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.inputLabel}>
          <label>Blog title</label>
        </div>
        <input
          type="text"
          placeholder="Blog title"
          value={title}
          className={styles.imgTextInput}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className={styles.block}>
        <div className={styles.inputLabel}>
          <label>Arabic blog title</label>
        </div>
        <input
          type="text"
          placeholder="Arabic blog title"
          value={titleAr}
          className={styles.imgTextInput}
          onChange={(e) => setTitleAr(e.target.value)}
        />
      </div>
      <div style={{marginBottom: '22px'}}>
        <div className={styles.inputLabel}>
          <label>English body</label>
        </div>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={modules}
        />
      </div>
      <div>
        <div className={styles.inputLabel}>
          <label>Arabic body</label>
        </div>
        <ReactQuill
          theme="snow"
          value={valueAr}
          onChange={setValueAr}
          modules={modules}
        />
      </div>
    </div>
  );
}

export default EditBlog;
