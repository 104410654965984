import React, {useState, useEffect, useContext} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Head from './Head';
import SideBar from './SideBar';

import Home from './Home';
import AddVideo from './AddVideo';
import EditVideo from './EditVideo';
import WebSites from './WebSites';
import AddCategory from './AddCategory';
import Images from './Images';
import Users from './Users';
import Blogs from "./Blogs";
import AddBlog from "./AddBlog";
import EditBlog from "./EditBlog";
import Texts from "./Texts";
import AddText from "./AddText";

import Login from './Login';

import { UserContext } from '../Context/UserContext';
import EditText from "./EditText";

export default function Root() {

  const {currUser, apiUrl} = useContext(UserContext);

  if(!apiUrl){
    return (
      <div>...loading</div>
    )
  }

  return (
    <div>
      {
        currUser && (Object.keys(currUser).length <= 0 || currUser?.isActive === 0 ) ? <Login/>
        :
        <Router >
          <Head/>
          <SideBar/>
          <div className='appContent' >
            <Switch >
              <Route exact path='/' >
                <Home/>
              </Route>
              <Route exact path='/add' >
                <AddVideo/>
              </Route>
              <Route path='/images' >
                <Images/>
              </Route>
              <Route path='/edit/:id' >
                <EditVideo/>
              </Route>
              <Route path='/websites' >
                <WebSites/>
              </Route>
              <Route path='/add-category' >
                <AddCategory/>
              </Route>
              <Route path='/users' >
                <Users/>
              </Route>
              <Route path='/blogs' >
                <Blogs/>
              </Route>
              <Route path='/new-blog' >
                <AddBlog/>
              </Route>
              <Route path='/edit-blog/:id' >
                <EditBlog/>
              </Route>
              <Route path='/texts' >
                <Texts/>
              </Route>
              <Route path='/new-text' >
                <AddText/>
              </Route>
              <Route path='/edit-text/:id' >
                <EditText/>
              </Route>
            </Switch>
          </div>
        </Router>
      }
    </div>
  )
}
