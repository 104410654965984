import React, {useState, useEffect, useContext} from 'react';
import styles from '../styles/login.module.scss';
import axios from 'axios';

import { UserContext } from '../Context/UserContext';

export default function Login() {

  const {setCurrUser, apiUrl} = useContext(UserContext);

  const [userName, setUserName] = useState("");
  const [pass, setPass] = useState("");

  const login = async () => {
    try {
      const response = await axios.post(`${apiUrl}/users/login`, {
        userName: userName,
        userPass: pass
      });
      let myObjectString = JSON.stringify(response.data);
      sessionStorage.setItem("currUser", myObjectString);
      setCurrUser(response.data);
    } catch (error) {
      console.error('Login error:', error.response || error.message);
    }
  };

  return (
    <div className={styles.loginContainer} >
      <div className={styles.loginWrapper} >
        <div className={styles.taqniyatImg} >
          <img alt="" src='https://taqniyat.sa/assets/images/logo.png' />
        </div>

        <div className={styles.formContainer} >
          <div className={styles.inputContainer} >
            <label>User name</label>
            <input
              type="text"
              placeholder='User name'
              value={userName}
              onChange={(e)=> setUserName(e.target.value) }
            />
          </div>
          <div className={styles.inputContainer}  >
            <label>Password</label>
            <input
              type="text"
              placeholder='Password'
              value={pass}
              onChange={(e)=> setPass(e.target.value) }
            />
          </div>
          <button className={styles.loginBtn} onClick={()=> login() } >
            Login
          </button>
        </div>
      </div>
    </div>
  )
}
