import React, {useContext} from 'react';
import styles from '../styles/nav.module.scss';

import { UserContext } from '../Context/UserContext';

export default function Head() {

  const {currUser} = useContext(UserContext);

  return (
    <header className={styles.head} >
      {/* <div className={styles.taqniyatImg} >
        <img src='https://taqniyat.sa/inc/uploads/2022/11/Taqniyat-Logo-En-Dark-e1669487857620.png' />
      </div> */}
      <div className={styles.headTitleView} >
        <span className={styles.headTitle} >Dashboard</span>
        <span className={styles.headMsg} >Welcome to CMS, {currUser && currUser.UserName}</span>
      </div>
    </header>
  )
}
