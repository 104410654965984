import React, {useEffect, useState, useContext} from 'react';
import styles from '../styles/websites.module.scss';
import { FiEdit2 } from 'react-icons/fi';
import axios from 'axios';

import { UserContext } from '../Context/UserContext';

import Modal from './Modal';

function AddNewPortal({close, apiUrl}){

  const [name, setName] = useState("");

  const addSite = async () => {
    try {
      const response = await axios.post(`${apiUrl}/sites/add`, { name });
      close();
      return response.data;
    } catch (error) {
      console.error('Error adding site:', error);
      throw new Error('Failed to add the site');
    }
  };

  return(
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between'}} >
      <div className={styles.inputWrapper} >
        <label>Portal title</label>
        <input
          placeholder='Portal title'
          value={name}
          onChange={(e)=> setName(e.target.value) }
        />
      </div>
      <button className={styles.savePortalBtn} onClick={()=> addSite() } >
        Add portal
      </button>
    </div>
  )
}

function PortalWrapper({item, apiUrl}){

  const [categories, setCategories] = useState([]);

  const getAllCategoriesForSite = async (siteId) => {
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      // throw new Error('Failed to fetch categories');
    }
  };

  useEffect(()=>{
    if(Object.keys(item).length > 0){
      console.log(item.id);
      getAllCategoriesForSite(item.id);
    }
  },[item])

  return(
    <div key={item.id} className={styles.portal} >
      <div className={styles.portalHeader} >
        <p className={styles.portalTitle} >{item.name}</p>
        {/* <FiEdit2 size={22} /> */}
      </div>
      <div className={styles.portalCategoriesWrapper} >
        {
          categories?.map((cat)=>
            <div key={cat.id} className={styles.portalCategory} >
              <span>{cat.en_title}</span>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default function WebSites() {

  const {apiUrl} = useContext(UserContext);

  const [isAddPortalModalOpen, setIsAddPortalModalOpen] = useState(false);
  const [sites, setSites] = useState([]);

  const openAddPortalModal = (item)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsAddPortalModalOpen(true);
  }
  const closeAddPortalModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsAddPortalModalOpen(false);
  }

  // const portalWrapper = (item)=>(
  //   <div key={item.name} className={styles.portal} >
  //     <div className={styles.portalHeader} >
  //       <p className={styles.portalTitle} >{item.name}</p>
  //       {/* <FiEdit2 size={22} /> */}
  //     </div>
  //     <div className={styles.portalCategoriesWrapper} >
  //       {
  //         item.categories?.map((cat)=>
  //           <div key={cat.id} className={styles.portalCategory} >
  //             <span>{cat.en_title}</span>
  //           </div>
  //         )
  //       }
  //     </div>
  //   </div>
  // )

  const getAllSites = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sites`);
      setSites(response.data);
    } catch (error) {
      console.error('Error fetching sites:', error);
      throw new Error('Failed to fetch sites');
    }
  };

  useEffect(()=>{
    // getAllSitesWithCategories();
    getAllSites();
  },[])

  return (
    <div className={styles.container} >
      <div className={styles.portalHeader} >
        <h2>List of Portals</h2>
        <button className={styles.addPortal} onClick={()=> openAddPortalModal() } >
          Add portal
        </button>
      </div>
      <div className={styles.portalsWrapper} >
        {sites.map((it)=> <PortalWrapper key={it.id} item={it} apiUrl={apiUrl} /> )}
      </div>
      <Modal isOpen={isAddPortalModalOpen} close={closeAddPortalModal} title="Add new portal" >
        <AddNewPortal close={closeAddPortalModal} apiUrl={apiUrl} />
      </Modal>
    </div>
  )
}
