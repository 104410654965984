import React, {useContext, useEffect, useState} from 'react';
import styles from "../styles/newText.module.scss";
import Modal from "./Modal";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import {UserContext} from "../Context/UserContext";
import {useHistory} from "react-router-dom";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import cx from "classnames";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['clean'],
    [{ 'direction': 'rtl' }]
  ],
};

function AddText(props) {

  const {apiUrl} = useContext(UserContext);

  const history = useHistory();

  const [value, setValue] = useState('');
  const [valueAr, setValueAr] = useState('');
  const [img, setImg] = useState(null);
  const [imgSrc, setImgSrc] = useState('');
  const [title, setTitle] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [imgUrl, setImgUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const [isSiteSelectOpen, setIsSiteSelectOpen] = useState(false);
  const [isCategoriesSelectOpen, setIsCategoriesSelectOpen] = useState(false);
  const [site, setSite] = useState({});
  const [category, setCategory] = useState({});
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);

  const selectSite = (site)=>{
    setSite(site);
    setCategory({});
    setIsSiteSelectOpen(false);
    setIsCategoriesSelectOpen(false);
  }
  const selectCat = (cat)=>{
    setCategory(cat);
    setIsCategoriesSelectOpen(false);
    setIsSiteSelectOpen(false);
  }

  const addText = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/texts/add`, {
        enTitle: titleAr,
        arTitle: titleAr,
        details: valueAr,
        siteId: site.id,
        categoryId: category.id
      }, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      });
      history.goBack();
      return response.data;
    } catch (error) {
      console.error('Error adding site:', error);
    } finally {
      setLoading(false);
    }
  };

  const getAllSites = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sites`);
      setSites(response.data);
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };
  const getAllCategoriesForSite = async (siteId) => {
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);
      console.log(response.data);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(()=>{
    if( Object.keys(site).length > 0 ){
      getAllCategoriesForSite(site.id);
    }
  },[site]);

  useEffect(()=>{
    getAllSites();
  },[]);

  return (
    <div className={styles.container}>
      <div className={styles.portalHeader}>
        <h2>New Text</h2>
        <button className={styles.addPortal} onClick={() => addText()} disabled={loading}>
          {
            loading
              ? <div className="loader"/>
              : "Save text"
          }
        </button>
      </div>
      <div className={styles.titleGrid}>
        <div className={styles.block}>
          <div className={styles.inputLabel}>
            <label>Arabic title</label>
          </div>
          <input
            type="text"
            placeholder="Arabic title"
            value={titleAr}
            className={styles.imgTextInput}
            onChange={(e) => setTitleAr(e.target.value)}
          />
        </div>
        <div className={styles.selectWrapper}>
          <label>Web site</label>
          <button className={styles.selectBtn} onClick={() => setIsSiteSelectOpen(!isSiteSelectOpen)}>
            {
              Object.keys(site).length > 0
                ? <span style={{color: '#000'}}>{site.name}</span>
                : <span style={{color: '#888'}}>Web site</span>
            }
            {
              isSiteSelectOpen
                ? <FiChevronUp size={22}/>
                : <FiChevronDown size={22}/>
            }
          </button>
          {
            isSiteSelectOpen &&
            <div className={styles.selectMenu}>
              <ul>
                {
                  sites.map((it) =>
                    <li key={it.id} onClick={() => selectSite(it)}
                        className={cx({[styles.activeItem]: site.id === it.id})}>{it.name}</li>
                  )
                }
              </ul>
            </div>
          }
        </div>
        <div className={styles.selectWrapper}>
          <label>Category</label>
          <button className={styles.selectBtn} onClick={() => setIsCategoriesSelectOpen(!isCategoriesSelectOpen)}>
            {
              Object.keys(category).length > 0
                ? <span style={{color: '#000'}}>{category.en_title}</span>
                : <span style={{color: '#888'}}>Category</span>
            }
            {
              isCategoriesSelectOpen
                ? <FiChevronUp size={22}/>
                : <FiChevronDown size={22}/>
            }
          </button>
          {
            isCategoriesSelectOpen &&
            <div className={styles.selectMenu}>
              <ul>
                {
                  categories.length > 0 &&
                  categories.map((it) => it.cat_type === "text" &&
                    <li key={it.id} onClick={() => selectCat(it)}
                        className={cx({[styles.activeItem]: category.id === it.id})}>{it.en_title}</li>
                  )
                }
              </ul>
            </div>
          }
        </div>
      </div>
      <div>
        <div className={styles.inputLabel}>
          <label>Arabic body</label>
        </div>
        <ReactQuill
          theme="snow"
          value={valueAr}
          onChange={setValueAr}
          modules={modules}
        />
      </div>
    </div>
  );
}

export default AddText;
