import React, {useState, useEffect, useContext} from 'react';
import styles from '../styles/newCategory.module.scss';
import { FiChevronDown, FiChevronUp, FiTrash } from 'react-icons/fi';
import cx from "classnames";
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../Context/UserContext';

export default function AddCategory() {

  const history = useHistory();
  const {apiUrl} = useContext(UserContext);

  const [enTitle, setEnTitle] = useState("");
  const [arTitle, setArTitle] = useState("");
  const [isSiteSelectOpen, setIsSiteSelectOpen] = useState(false);
  const [site, setSite] = useState({});
  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState(false);
  const [type, setType] = useState("");
  const [img, setImg] = useState(null);
  const [imgUrl, setImgUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [sites, setSites] = useState([]);

  const getAllSites = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sites`);
      setSites(response.data);
    } catch (error) {
      console.error('Error fetching sites:', error);
      throw new Error('Failed to fetch sites');
    }
  };

  const selectSite = (site)=>{
    setSite(site);
    setIsSiteSelectOpen(false);
  }
  const selectType = (site)=>{
    setType(site);
    setIsTypeSelectOpen(false);
  }

  const handleImageFileChange = (event)=>{
    const selectedFile = event.target.files[0];
    setImg(selectedFile);
  }

  const cleanImg= ()=>{
    setImg(null);
  }

  const addVideo = async ()=>{
    if(Object.keys(site).length === 0){
      setErrorMsg("Please select a specific Site");
      return;
    }
    if(type.length === 0){
      setErrorMsg("Please select a type");
      return;
    }
    if(enTitle.length === 0){
      setErrorMsg("Please add a english title");
      return;
    }
    if(arTitle.length === 0){
      setErrorMsg("Please add a arabic title");
      return;
    }
    if(imgUrl.length === 0){
      setErrorMsg("Please add a image url for the category");
      return;
    }

    const catData = {
      enTitle: enTitle,
      arTitle: arTitle,
      siteId: site.id,
      image: imgUrl,
      type
    };

    try {
      const response = await axios.post(`${apiUrl}/categories/add`, catData);
      console.log('Video added:', response.data);
      history.push("/");
    } catch (error) {
      console.error('Error adding category:', error);
      throw new Error('Failed to add the category');
    }
  }

  useEffect(()=>{
    getAllSites();
  },[])

  return (
    <div className={styles.addVideoContainer} >
      <div className={styles.inputsContainer} >
        <p className={styles.title} >Add new category</p>

        <div className={styles.selectWrapper} >
          <label>Web site</label>
          <button className={styles.selectBtn} onClick={()=> setIsSiteSelectOpen(!isSiteSelectOpen) } >
            {
              Object.keys(site).length > 0
              ? <span style={{color: '#000'}} >{site.name}</span>
              : <span style={{color: '#888'}} >Web site</span>
            }
            {
              isSiteSelectOpen
              ? <FiChevronUp size={22} />
              : <FiChevronDown size={22} />
            }
          </button>
          {
            isSiteSelectOpen &&
            <div className={styles.selectMenu} >
              <ul>
                {
                  sites.map((it)=>
                    <li key={it.id} onClick={()=> selectSite(it) } className={cx({[styles.activeItem]: site.id === it.id})} >{it.name}</li>
                  )
                }
              </ul>
            </div>
          }
        </div>
        <div className={styles.selectWrapper} >
          <label>Category type</label>
          <button className={styles.selectBtn} onClick={()=> setIsTypeSelectOpen(!isTypeSelectOpen) } >
            {
              type.length > 0
              ? <span style={{color: '#000'}} >{type}</span>
              : <span style={{color: '#888'}} >Category type</span>
            }
            {
              isTypeSelectOpen
              ? <FiChevronUp size={22} />
              : <FiChevronDown size={22} />
            }
          </button>
          {
            isTypeSelectOpen &&
            <div className={styles.selectMenu} >
              <ul>
                <li onClick={() => selectType("video")} className={cx({[styles.activeItem]: type === "video"})}>video
                </li>
                <li onClick={() => selectType("image")} className={cx({[styles.activeItem]: type === "image"})}>image
                </li>
                <li onClick={() => selectType("text")} className={cx({[styles.activeItem]: type === "text"})}>text
                </li>
              </ul>
            </div>
          }
        </div>

        <div className={styles.inputWrapper}>
          <label>English title</label>
          <input
            placeholder='English title'
            value={enTitle}
            onChange={(e)=> setEnTitle(e.target.value) }
          />
        </div>
        <div className={styles.inputWrapper} >
          <label>Arabic title</label>
          <input
            placeholder='Arabic title'
            value={arTitle}
            onChange={(e)=> setArTitle(e.target.value) }
          />
        </div>
        <div className={styles.inputWrapper} >
          <label>Image url</label>
          <input
            placeholder='Image url'
            value={imgUrl}
            onChange={(e)=> setImgUrl(e.target.value) }
          />
        </div>

        <button className={styles.submitBtn} onClick={()=> addVideo() } >
          Add category
        </button>

      </div>
      <div style={{width: '50%', marginTop: '40px'}} >
        {errorMsg && <p style={{color:"#D6323A", textAlign: 'center'}} >{errorMsg}</p> }
        {/* <div className={styles.fileUploadWrapper} >
          <div className={styles.fileUploadHead} >
            <p className={styles.fileUploadTitle} >Category image</p>
            <div>
              { img !== null && <FiTrash size={22} cursor="pointer" color='#888' onClick={()=> cleanImg() } style={{marginRight: '12px'}}/> }
            </div>
          </div>
          <div className={styles.fileWrapper} >
            {
              img === null
              ? <input type='file' onChange={(event)=> handleImageFileChange(event) } />
              : <img src={URL.createObjectURL(img)} style={{height: '100%', width: 'auto', objectFit: 'contain'}}/>
            }
          </div>
        </div> */}
      </div>
    </div>
  )
}
