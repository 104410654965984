import React, {useState, useEffect, useContext} from 'react';
import styles from "../styles/blogs.module.scss";
import Modal from "./Modal";
import {Link, useHistory} from "react-router-dom";
import {FiEdit, FiTrash} from "react-icons/fi";
import axios from "axios";
import {UserContext} from "../Context/UserContext";

function Blogs(props) {

  const {apiUrl} = useContext(UserContext);

  const history = useHistory();

  const [blogs, setBlogs] = useState([]);

  const removeBlog = async (id) => {
    // Confirm before deleting
    if (window.confirm('Are you sure you want to delete this blog post?')) {
      try {
        await axios.delete(`${apiUrl}/blogs/delete/${id}`);
        console.log('Blog deleted successfully');
        getBlogs();
        alert('Blog deleted successfully');  // Optional: alert user of success
      } catch (error) {
        console.error('Error deleting blog:', error);
        alert('Failed to delete the blog.'); // Alert user of failure
      }
    } else {
      // User clicked "Cancel" in the confirm dialog
      console.log('Deletion canceled by user.');
    }
  }

  const editBlog = (id) => {
    history.push(`/edit-blog/${id}`);
  };

  const getBlogs = async () => {
    try {
      const response = await axios.get(`${apiUrl}/blogs`);
      const data = response.data;
      console.log(data);
      setBlogs(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  }

  useEffect(()=>{
    getBlogs();
  },[])

  return (
    <div className={styles.container}>
      <div className={styles.portalHeader}>
        <h2>List of Blogs</h2>
        <Link to="/new-blog" className={styles.addPortal}>
          Add blog
        </Link>
      </div>
      <div className={styles.blogsWrapper} >
        {
          blogs.map((item) =>
            <div key={item.id} className={styles.blogContainer}>
              <div className={styles.blogImg}>
                <img src={item.img} alt="blogs" />
              </div>
              <p style={{fontSize: 18, fontWeight: '600', margin: 2}} >{item?.title}</p>
              <div style={{display: 'flex', alignItems: 'center', gap: '8px'}} >
                <div className={styles.blogBody} dangerouslySetInnerHTML={{__html: item.body}}/>
                <div className={styles.blogBody} dangerouslySetInnerHTML={{__html: item.bodyAr}}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: '12px', justifyContent: 'flex-end'}} >
                <FiEdit style={{cursor: 'pointer'}} onClick={()=> editBlog(item.id) } />
                <FiTrash style={{cursor: 'pointer'}} onClick={()=> removeBlog(item.id) } />
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Blogs;
