import React, {useState, useEffect, useContext} from 'react';
import styles from '../styles/users.module.scss';
import axios from 'axios';

import Modal from './Modal';
import { UserContext } from '../Context/UserContext';
import { FiEdit2, FiTrash, FiTrash2 } from 'react-icons/fi';

function AddUser({close, apiUrl}){

  const [userName, setUserName] = useState("");
  const [pass, setPass] = useState("");
  const [isActive, setIsActive] = useState(1);

  const addUser = async () => {
    try {
      const response = await axios.post(`${apiUrl}/users/add`, {
        userName,
        userPass: pass,
        isActive
      });
      console.log('User added:', response.data);
      close();
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  return(
    <div >
      <div className={styles.addUserModal} >
        <div className={styles.inputContainer} >
          <label>User name</label>
          <input
            type="text"
            placeholder='User name'
            value={userName}
            onChange={(e)=> setUserName(e.target.value) }
          />
        </div>
        <div className={styles.inputContainer}  >
          <label>Password</label>
          <input
            type="text"
            placeholder='Password'
            value={pass}
            onChange={(e)=> setPass(e.target.value) }
          />
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '100%'}} >
          <div className={styles.customRadioContainer} >
            <div className={styles.customRadio} onClick={()=> setIsActive(1) } >
              { isActive === 1 && <div/>}
            </div>
            Activate
          </div>
          <div className={styles.customRadioContainer} >
            <div className={styles.customRadio} onClick={()=> setIsActive(0) } >
              { isActive === 0 && <div/>}
            </div>
            Desactivate
          </div>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}} >
        <button className={styles.submitBtn} onClick={()=> addUser() } >
          Add user
        </button>
      </div>
    </div>
  )
}

function EditUser({close, apiUrl, userData}){

  const [userName, setUserName] = useState("");
  const [pass, setPass] = useState("");
  const [isActive, setIsActive] = useState(1);

  const editUser = async () => {
    try {
      const response = await axios.put(`${apiUrl}/users/update`, {
        id: userData.Id,
        userName,
        userPass: pass,
        isActive
      });
      console.log('User updated:', response.data);
      close();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  useEffect(()=>{
    if(Object.keys(userData).length > 0){
      setUserName(userData.UserName);
      setPass(userData.UserPass);
      setIsActive(userData.IsActive);
    }
  },[userData])

  return(
    <div >
      <div className={styles.addUserModal} >
        <div className={styles.inputContainer} >
          <label>User name</label>
          <input
            type="text"
            placeholder='User name'
            value={userName}
            onChange={(e)=> setUserName(e.target.value) }
          />
        </div>
        <div className={styles.inputContainer}  >
          <label>Password</label>
          <input
            type="text"
            placeholder='Password'
            value={pass}
            onChange={(e)=> setPass(e.target.value) }
          />
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '100%'}} >
          <div className={styles.customRadioContainer} >
            <div className={styles.customRadio} onClick={()=> setIsActive(1) } >
              { isActive === 1 && <div/>}
            </div>
            Activate
          </div>
          <div className={styles.customRadioContainer} >
            <div className={styles.customRadio} onClick={()=> setIsActive(0) } >
              { isActive === 0 && <div/>}
            </div>
            Desactivate
          </div>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}} >
        <button className={styles.submitBtn} onClick={()=> editUser() } >
          Edit user
        </button>
      </div>
    </div>
  )
}

export default function Users() {

  const {apiUrl} = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState({});

  const openAddUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsAddUserModalOpen(true);
  }
  const closeAddUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsAddUserModalOpen(false);
    getAllUsers();
  }
  const openEditUserModal = (user)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setEditModalData(user);
    console.log(user);
    setIsEditUserModalOpen(true);
  }
  const closeEditUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setEditModalData({});
    setIsEditUserModalOpen(false);
    getAllUsers();
  }

  const deleteUser = async (userId) => {
    try {
      await axios.delete(`${apiUrl}/users/delete/${userId}`);
      console.log('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const getAllUsers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error.response || error.message);
      // Optionally update the state to show an error message to the user
    }
  }; 

  useEffect(() => {
    if(apiUrl){
      getAllUsers();
    }
  }, [apiUrl]);

  return (
    <div className={styles.container} >
      <div className={styles.portalHeader} >
        <h2>Users</h2>
        <button className={styles.addPortal} onClick={()=> openAddUserModal() } >
          Add user
        </button>
      </div>
      <div>
        <table className={styles.table} >
          <thead>
            <tr>
              <th>Id</th>
              <th>User name</th>
              <th>Password</th>
              <th>Is active</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              users && users.map((it)=>
                <tr key={it.Id} >
                  <td >{it.Id}</td>
                  <td >{it.UserName}</td>
                  <td >{it.UserPass}</td>
                  <td >{it.IsActive === 1 ? "True": "False"}</td>
                  <td style={{display: 'flex', gap: '12px'}} >
                    <FiEdit2 size={18} className={styles.tableEditIcon} onClick={()=> openEditUserModal(it) } />
                    <FiTrash2 size={18} className={styles.tableDeleteIcon} onClick={()=> deleteUser(it.Id) } />
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
      <Modal isOpen={isAddUserModalOpen} close={closeAddUserModal} title="Add new user" >
        <AddUser close={closeAddUserModal} apiUrl={apiUrl} />
      </Modal>
      <Modal isOpen={isEditUserModalOpen} close={closeEditUserModal} title="Edit user" >
        <EditUser close={closeEditUserModal} apiUrl={apiUrl} userData={editModalData} />
      </Modal>
    </div>
  )
}
