import React, {useState, useEffect, useContext} from 'react';
import styles from '../styles/addvideo.module.scss';
import { FiChevronDown, FiChevronUp, FiTrash } from 'react-icons/fi';
import cx from "classnames";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from 'axios';

import { UserContext } from '../Context/UserContext';

const hiyaCategories = ["Your beauty", "Your career", "Your personality", "Nine months' journey"];
const alphaWorldCategories = ["Turkish World", "Chineses World", "English World", "French World"];

export default function EditVideo() {

  let { id } = useParams();
  let location = useLocation();
  const history = useHistory();
  const {apiUrl} = useContext(UserContext);

  const [enTitle, setEnTitle] = useState("");
  const [arTitle, setArTitle] = useState("");
  const [isSiteSelectOpen, setIsSiteSelectOpen] = useState(false);
  const [isCatSelectOpen, setIsCatSelectOpen] = useState(false);
  const [site, setSite] = useState({});
  const [cat, setCat] = useState({});
  const [isVideoUploaderOpen, setIsVideoUploaderOpen] = useState(false);
  const [isImgUploaderOpen, setIsImgUploaderOpen] = useState(false);
  const [video, setVideo] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [img, setImg] = useState(null);
  const [videoVimeoId, setVideoVimeoId] = useState(null);
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);

  const selectSite = (site)=>{
    setSite(site);
    setIsSiteSelectOpen(false);
  }
  const selectCat = (cat)=>{
    setCat(cat);
    setIsCatSelectOpen(false);
  }

  const handleVideoFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const src = URL.createObjectURL(selectedFile);
      setVideoSrc(src);
      setVideo(selectedFile); // Save the file in state for future upload
    }
  };
  const handleImageFileChange = (event)=>{
    const selectedFile = event.target.files[0];
    setImg(selectedFile);
  }
  const cleanVideo = ()=>{
    setVideo(null);
    setVideoSrc(null);
  }

  const cleanImg= ()=>{
    setImg(null);
  }

  const getAllSites = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sites`);
      setSites(response.data);
    } catch (error) {
      console.error('Error fetching sites:', error);
      throw new Error('Failed to fetch sites');
    }
  };
  const getAllCategoriesForSite = async (siteId) => {
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  };
  const getSiteById = (id)=>{
    axios.get(`${apiUrl}/site/${id}`)
    .then(response => {
      setSite(response.data);
    })
    .catch(error => {
      console.error(error);
    });
  }
  const getCatById = (id)=>{
    axios.get(`${apiUrl}/category/${id}`)
    .then(response => {
      setCat(response.data);
    })
    .catch(error => {
      console.error(error);
    });
  }

  const getVideoById = (id)=>{
    axios.get(`${apiUrl}/video/${id}`)
    .then(response => {
      const videoId = response.data.video_url.split('/').pop();
      setVideoVimeoId(videoId);
      setEnTitle(response.data.en_title);
      setArTitle(response.data.ar_title);
      getCatById(response.data.category_id);
      getSiteById(response.data.site_id);
    })
    .catch(error => {
      console.error(error);
    });
  }

  const updateVideo = async ()=>{
    const videoData = {
      en_title: enTitle,
      ar_title: arTitle,
      site: site.id, 
      category: cat.id
    };
    try {
      // Make a POST request to the backend API
      const response = await axios.put(`${apiUrl}/update/${id}`, videoData);
  
      // Handle the response from the backend here
      console.log('Video updated:', response.data);
      history.push("/");
    } catch (error) {
      // Handle any errors here
      console.error('There was an error adding the video:', error.response ? error.response.data : error.message);
    }
  }

  useEffect(() => {
    return () => {
      if (videoSrc) {
        URL.revokeObjectURL(videoSrc);
      }
    };
  }, [videoSrc]);

  useEffect(()=>{
    getVideoById(id);
  },[location.pathname]);

  useEffect(()=>{
    if( Object.keys(site).length > 0 ){
      getAllCategoriesForSite(site.id);
    }
  },[site])
  useEffect(()=>{
    getAllSites();
  },[])
  
  return (
    <div className={styles.addVideoContainer} >
      <div className={styles.inputsContainer} >
      <p className={styles.title} >Edit video</p>
        <div className={styles.inputWrapper} >
          <label>English title</label>
          <input
            placeholder='English title'
            value={enTitle}
            onChange={(e)=> setEnTitle(e.target.value) }
          />
        </div>
        <div className={styles.inputWrapper} >
          <label>Arabic title</label>
          <input
            placeholder='Arabic title'
            value={arTitle}
            onChange={(e)=> setArTitle(e.target.value) }
          />
        </div>

        <div style={{display: 'flex',justifyContent: 'space-between'}} >
          <div className={styles.selectWrapper} >
            <label>Web site</label>
            <button className={styles.selectBtn} onClick={()=> setIsSiteSelectOpen(!isSiteSelectOpen) } >
              {
                Object.keys(site).length > 0
                ? <span style={{color: '#000'}} >{site.name}</span>
                : <span style={{color: '#888'}} >Web site</span>
              }
              {
                isSiteSelectOpen 
                ? <FiChevronUp size={22} />
                : <FiChevronDown size={22} />
              }
            </button>
            {
              isSiteSelectOpen &&
              <div className={styles.selectMenu} >
                <ul>
                  {sites.map((it)=>
                    <li key={it.id} onClick={()=> selectSite(it) } className={cx({[styles.activeItem]:site.id === it.id})} >{it.name}</li>
                  )}
                </ul>
              </div>
            }
          </div>
          <div className={styles.selectWrapper} >
            <label>Category</label>
            <button className={styles.selectBtn} onClick={()=> setIsCatSelectOpen(!isCatSelectOpen) } >
              {
                Object.keys(cat).length > 0
                ? <span style={{color: '#000'}} >{cat.en_title}</span>
                : <span style={{color: '#888'}} >Category</span>
              }
              {
                isCatSelectOpen 
                ? <FiChevronUp size={22} />
                : <FiChevronDown size={22} />
              }
            </button>
            {
              isCatSelectOpen &&
              <div className={styles.selectMenu} >
                <ul>
                  {
                    categories.length > 0 &&
                    categories.map((it)=> <li key={it.id} onClick={()=> selectCat(it) } className={cx({[styles.activeItem]:cat.id === it.id})} >{it.en_title}</li> )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        
        <button className={styles.submitBtn} onClick={()=> updateVideo() } >
          Edit video
        </button>

      </div>
      <div style={{width: '50%', marginTop: '40px'}} >
        <div className={styles.fileUploadWrapper} >
          <div className={styles.fileUploadHead} >
            <p className={styles.fileUploadTitle} >Video</p>
            <div>
              { video !== null && <FiTrash size={22} cursor="pointer" color='#888' onClick={()=> cleanVideo() } style={{marginRight: '12px'}}/> }
              {
                isVideoUploaderOpen 
                ? <FiChevronUp size={22} cursor="pointer" color='#888' onClick={()=> setIsVideoUploaderOpen(false) } />
                : <FiChevronDown size={22} cursor="pointer" color='#888' onClick={()=> setIsVideoUploaderOpen(true) } />
              }
            </div>
          </div>
          {
            isVideoUploaderOpen &&
            <div className={styles.fileWrapper} >
              <iframe src={`https://player.vimeo.com/video/${videoVimeoId}?byline=0&portrait=0&title=0`} width={window.innerWidth*0.6} height={window.innerHeight*0.6} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
          }
        </div>
        {/* <div className={styles.fileUploadWrapper} >
          <div className={styles.fileUploadHead} >
            <p className={styles.fileUploadTitle} >Upload thumbnail</p>
            <div>
              { img !== null && <FiTrash size={22} cursor="pointer" color='#888' onClick={()=> cleanImg() } style={{marginRight: '12px'}}/> }
              {
                isImgUploaderOpen 
                ? <FiChevronUp size={22} cursor="pointer" color='#888' onClick={()=> setIsImgUploaderOpen(false) } />
                : <FiChevronDown size={22} cursor="pointer" color='#888' onClick={()=> setIsImgUploaderOpen(true) } />
              }
            </div>
          </div>
          {
            isImgUploaderOpen &&
            <div className={styles.fileWrapper} >
              {
                img === null 
                ? <input type='file' onChange={(event)=> handleImageFileChange(event) } />
                : <img src={URL.createObjectURL(img)} style={{height: '100%', width: 'auto', objectFit: 'contain'}}/>
              }
            </div>
          }
        </div> */}
      </div>
    </div>
  )
}
