import './App.scss';
import './styles/loading.css';
import React from 'react';

import Root from './Components/Root';

import { UserProvider } from './Context/UserContext';

function App() {

  return (
    <UserProvider>
      <Root/>
    </UserProvider>
  );
}

export default App;
