import React, {useState, useEffect, useContext} from 'react';
import styles from "../styles/text.module.scss";
import Modal from "./Modal";
import {Link, useHistory} from "react-router-dom";
import {FiEdit, FiTrash} from "react-icons/fi";
import axios from "axios";
import {UserContext} from "../Context/UserContext";

function Texts(props) {

  const {apiUrl} = useContext(UserContext);

  const history = useHistory();

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const removeText = async (id) => {
    // Confirm before deleting
    if (window.confirm('Are you sure you want to delete this text post?')) {
      try {
        await axios.delete(`${apiUrl}/texts/delete/${id}`);
        console.log('Text deleted successfully');
        getTexts();
        alert('Text deleted successfully');  // Optional: alert user of success
      } catch (error) {
        console.error('Error deleting Text:', error);
        alert('Failed to delete the Text.'); // Alert user of failure
      }
    } else {
      // User clicked "Cancel" in the confirm dialog
      console.log('Deletion canceled by user.');
    }
  }

  const editText = (id) => {
    history.push(`/edit-text/${id}`);
  };

  const getTexts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/texts`);
      const data = response.data;
      console.log(data);
      setBlogs(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    } finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    getTexts();
  },[]);

  if(loading){
    return (
      <div className={styles.container}>
        loading...
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.portalHeader}>
        <h2>List of Texts</h2>
        <Link to="/new-text" className={styles.addPortal}>
          Add text
        </Link>
      </div>
      <div className={styles.blogsWrapper} >
        {
          blogs.map((item) =>
            <div key={item.id} className={styles.blogContainer}>
              <div className={styles.blogHeader} >
                {/*<p  >{item?.en_title}</p>*/}
                <p className={styles.textTitle} style={{direction: 'rtl'}}>{item?.ar_title}</p>
              </div>
              <div >
                <div className={styles.blogBody} dangerouslySetInnerHTML={{__html: item.details}}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: '12px', justifyContent: 'flex-end'}}>
                <FiEdit style={{cursor: 'pointer'}} onClick={() => editText(item.id)}/>
                <FiTrash style={{cursor: 'pointer'}} onClick={() => removeText(item.id)}/>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Texts;
