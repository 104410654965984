import React, {useState, useEffect, useContext} from 'react';
import styles from '../styles/addvideo.module.scss';
import { FiChevronDown, FiChevronUp, FiTrash } from 'react-icons/fi';
import cx from "classnames";
import axios from 'axios';
import * as tus from 'tus-js-client';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../Context/UserContext';

const hiyaCategories = ["Your beauty", "Your career", "Your personality", "Nine months' journey"];
const alphaWorldCategories = ["Turkish World", "Chineses World", "English World", "French World"];

export default function AddVideo() {

  const history = useHistory();
  const {apiUrl} = useContext(UserContext);
  
  const [enTitle, setEnTitle] = useState("");
  const [arTitle, setArTitle] = useState("");
  const [isSiteSelectOpen, setIsSiteSelectOpen] = useState(false);
  const [isCatSelectOpen, setIsCatSelectOpen] = useState(false);
  const [site, setSite] = useState({});
  const [cat, setCat] = useState({});
  const [isVideoUploaderOpen, setIsVideoUploaderOpen] = useState(false);
  const [isImgUploaderOpen, setIsImgUploaderOpen] = useState(false);
  const [video, setVideo] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPercentage, setVideoPercentage] = useState("");
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);

  const selectSite = (site)=>{
    setSite(site);
    setIsSiteSelectOpen(false);
  }
  const selectCat = (cat)=>{
    setCat(cat);
    setIsCatSelectOpen(false);
  }

  const handleVideoFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const src = URL.createObjectURL(selectedFile);
      setVideoSrc(src);
      setVideo(selectedFile); // Save the file in state for future upload
    }
  };
  const handleImageFileChange = (event)=>{
    const selectedFile = event.target.files[0];
    setImg(selectedFile);
  }
  const cleanVideo = ()=>{
    setVideo(null);
    setVideoSrc(null);
  }

  const cleanImg= ()=>{
    setImg(null);
  }

  const getAllSites = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sites`);
      setSites(response.data);
    } catch (error) {
      console.error('Error fetching sites:', error);
      throw new Error('Failed to fetch sites');
    }
  };
  const getAllCategoriesForSite = async (siteId) => {
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  };

  const uploadVideo = () => {
    setErrorMsg(null);
    if (!video) {
      return;
    }
    if(enTitle.length === 0){
      setErrorMsg("Please type the english title");
      return;
    }
    setIsVideoLoading(true);
    axios.post(`${apiUrl}/vimeo/upload`, { size: video.size, name: enTitle })
    .then(response => {
      const uploadLink = response.data.uploadLink;
      const videoLink = response.data.videoLink;

      const upload = new tus.Upload(video, {
        uploadUrl: uploadLink,
        retryDelays: [0, 1000, 3000, 5000],
        metadata: {
          filename: video.name,
          filetype: video.type
        },
        onError: (error) => {
          console.log("Failed because: " + error)
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
          setVideoPercentage(`${percentage}%`);
          console.log(bytesUploaded, bytesTotal, `${percentage}%`);
        },
        onSuccess: () => {
          console.log("Download %s from %s", upload.file.name, upload.url)
          setVideoUrl(videoLink);
          setIsVideoLoading(false);
        }
      })

      upload.start()
    })
    .catch(error => {
      console.error('Error obtaining upload link:', error);
      setIsVideoLoading(false);
    });
  };

  const addVideo = async ()=>{
    if(videoUrl.length === 0){
      setErrorMsg("Please add a video first");
      return;
    }
    if(Object.keys(site).length === 0){
      setErrorMsg("Please select a specific Site");
      return;
    }
    if(Object.keys(cat).length === 0){
      setErrorMsg("Please select a category");
      return;
    }
    const videoData = {
      url: videoUrl,
      en_title: enTitle,
      ar_title: arTitle,
      thumbnail: '', 
      site: site.id, 
      category: cat.id
    };
    try {
      // Make a POST request to the backend API
      const response = await axios.post(`${apiUrl}/add`, videoData);
  
      // Handle the response from the backend here
      console.log('Video added:', response.data);
      history.push("/");
    } catch (error) {
      // Handle any errors here
      console.error('There was an error adding the video:', error.response ? error.response.data : error.message);
    }
  }

  useEffect(() => {
    return () => {
      if (videoSrc) {
        URL.revokeObjectURL(videoSrc);
      }
    };
  }, [videoSrc]);

  useEffect(()=>{
    if( Object.keys(site).length > 0 ){
      getAllCategoriesForSite(site.id);
    }
  },[site])
  useEffect(()=>{
    getAllSites();
  },[])
  
  return (
    <div className={styles.addVideoContainer} >
      <div className={styles.inputsContainer} >
        <p className={styles.title} >Add new video</p>
        <div className={styles.inputWrapper} >
          <label>English title</label>
          <input
            placeholder='English title'
            value={enTitle}
            onChange={(e)=> setEnTitle(e.target.value) }
          />
        </div>
        <div className={styles.inputWrapper} >
          <label>Arabic title</label>
          <input
            placeholder='Arabic title'
            value={arTitle}
            onChange={(e)=> setArTitle(e.target.value) }
          />
        </div>
        <div className={styles.inputWrapper} >
          <label>Video URL</label>
          <input
            placeholder='Video URL'
            value={videoUrl}
            onChange={(e)=> setVideoUrl(e.target.value) }
          />
        </div>

        <div style={{display: 'flex',justifyContent: 'space-between'}} >
          <div className={styles.selectWrapper} >
            <label>Web site</label>
            <button className={styles.selectBtn} onClick={()=> setIsSiteSelectOpen(!isSiteSelectOpen) } >
              {
                Object.keys(site).length > 0
                ? <span style={{color: '#000'}} >{site.name}</span>
                : <span style={{color: '#888'}} >Web site</span>
              }
              {
                isSiteSelectOpen 
                ? <FiChevronUp size={22} />
                : <FiChevronDown size={22} />
              }
            </button>
            {
              isSiteSelectOpen &&
              <div className={styles.selectMenu} >
                <ul>
                  {
                    sites.map((it)=>
                      <li key={it.id} onClick={()=> selectSite(it) } className={cx({[styles.activeItem]:site.id === it.id})} >{it.name}</li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
          <div className={styles.selectWrapper} >
            <label>Category</label>
            <button className={styles.selectBtn} onClick={()=> setIsCatSelectOpen(!isCatSelectOpen) } >
              {
                Object.keys(cat).length > 0
                ? <span style={{color: '#000'}} >{cat.en_title}</span>
                : <span style={{color: '#888'}} >Category</span>
              }
              {
                isCatSelectOpen 
                ? <FiChevronUp size={22} />
                : <FiChevronDown size={22} />
              }
            </button>
            {
              isCatSelectOpen &&
              <div className={styles.selectMenu} >
                <ul>
                  {
                    categories.length > 0 &&
                    categories.map((it)=> it.cat_type === "video" && <li key={it.id} onClick={()=> selectCat(it) } className={cx({[styles.activeItem]:cat.id === it.id})} >{it.en_title}</li> )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        
        <button className={styles.submitBtn} onClick={()=> addVideo() } >
          Add video
        </button>

      </div>
      <div style={{width: '50%', marginTop: '40px'}} >
        {errorMsg && <p style={{color:"#D6323A", textAlign: 'center'}} >{errorMsg}</p> }
        <div className={styles.fileUploadWrapper} >
          <div className={styles.fileUploadHead} >
            <p className={styles.fileUploadTitle} >Upload video</p>
            <div>
              { video !== null && <FiTrash size={22} cursor="pointer" color='#888' onClick={()=> cleanVideo() } style={{marginRight: '12px'}}/> }
              {
                isVideoUploaderOpen 
                ? <FiChevronUp size={22} cursor="pointer" color='#888' onClick={()=> setIsVideoUploaderOpen(false) } />
                : <FiChevronDown size={22} cursor="pointer" color='#888' onClick={()=> setIsVideoUploaderOpen(true) } />
              }
            </div>
          </div>
          {
            isVideoUploaderOpen &&
            <div className={styles.fileWrapper} >
              {
                video === null 
                ? <input type='file' onChange={(event)=> handleVideoFileChange(event) } />
                : <video src={videoSrc} width="auto" height="300" controls />
              }
            </div>
          }
        </div>
        { video !== null && 
        <div style={{display: 'flex', justifyContent: 'center'}} >
          {
            videoUrl.length > 0 ?
            <div className={styles.videoLoading} >Video uploaded with Success</div>
            :
            isVideoLoading ?
            <div className={styles.videoLoading} >
              loading ... {videoPercentage}
            </div>
            :
            <button className={styles.uploadVidBtn} onClick={()=> uploadVideo()} >
              upload video
            </button>
          }
        </div>}
        {/* <div className={styles.fileUploadWrapper} >
          <div className={styles.fileUploadHead} >
            <p className={styles.fileUploadTitle} >Upload thumbnail</p>
            <div>
              { img !== null && <FiTrash size={22} cursor="pointer" color='#888' onClick={()=> cleanImg() } style={{marginRight: '12px'}}/> }
              {
                isImgUploaderOpen 
                ? <FiChevronUp size={22} cursor="pointer" color='#888' onClick={()=> setIsImgUploaderOpen(false) } />
                : <FiChevronDown size={22} cursor="pointer" color='#888' onClick={()=> setIsImgUploaderOpen(true) } />
              }
            </div>
          </div>
          {
            isImgUploaderOpen &&
            <div className={styles.fileWrapper} >
              {
                img === null 
                ? <input type='file' onChange={(event)=> handleImageFileChange(event) } />
                : <img src={URL.createObjectURL(img)} style={{height: '100%', width: 'auto', objectFit: 'contain'}}/>
              }
            </div>
          }
        </div> */}
      </div>
    </div>
  )
}
